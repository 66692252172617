'use client';

// TODO fix jsx-a11y

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import React, { useState } from 'react';
import HTMLString from 'react-html-string';

import type { Locale } from '@shared/libs';
import { ButtonExpand, ButtonExpandType, H2, UnboundedFont } from '@shared/ui';

import { FaqOption } from './consts';
import styles from './faq.module.css';

type TProps = {
  locale: Locale;
  instruments?: string;
  arrangement?: string;
  mt?: boolean;
};

const Faq = ({ locale, instruments, arrangement, mt }: TProps) => {
  const [faqOptionsMap, setFaqOptionsMap] = useState<FaqOption[]>([
    {
      id: 'faq_1',
      title: locale.NOTE_FAQ_Q1 || '',
      description: locale.NOTE_FAQ_A1 || '',
      isExpanded: false,
    },
    {
      id: 'faq_2',
      title: locale.NOTE_FAQ_Q2 || '',
      description: locale.NOTE_FAQ_A2 || '',
      isExpanded: false,
    },
    {
      id: 'faq_3',
      title: locale.NOTE_FAQ_Q3 || '',
      description: locale.NOTE_FAQ_A3 || '',
      isExpanded: false,
    },
    {
      id: 'faq_4',
      title: locale.NOTE_FAQ_Q4 || '',
      description: locale.NOTE_FAQ_A4 || '',
      isExpanded: false,
    },
    {
      id: 'faq_5',
      title: locale.NOTE_FAQ_Q5 || '',
      description: locale.NOTE_FAQ_A5 || '',
      isExpanded: false,
    },
    {
      id: 'faq_6',
      title: locale.NOTE_FAQ_Q6 || '',
      description: `<table class="${styles.table}">
      <tr><td>${locale.NOTE_FAQ_A6_1_1}</td><td>${locale.NOTE_FAQ_A6_1_2}</td></tr>
      ${arrangement ? `<tr><td>${locale.NOTE_FAQ_A6_2_1}</td><td>${arrangement}</td></tr>` : ''}
      ${instruments ? `<tr><td>${locale.NOTE_FAQ_A6_3_1}</td><td>${instruments}</td></tr>` : ''} 
      <tr><td>${locale.NOTE_FAQ_A6_4_1}</td><td>${locale.NOTE_FAQ_A6_4_2}</td></tr>
      </table>`,
      isExpanded: true,
    },
  ]);

  const toggleAccordion = (event: React.MouseEvent) => {
    const targetId = event.currentTarget.id;
    if (!targetId) return;
    const newState = faqOptionsMap.map((option) =>
      option.id === targetId ? { ...option, isExpanded: !option.isExpanded } : option,
    );
    setFaqOptionsMap(newState);
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.mt]: mt,
      })}
      itemScope
      itemType="https://schema.org/FAQPage"
    >
      <H2 className={styles.h2} itemProp="name">
        {locale.NOTE_FAQ_TITLE}
      </H2>

      <div>
        {faqOptionsMap.map((option) => (
          <div
            key={option.id}
            className={styles.accordionItem}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
          >
            <div
              className={cn(styles.toggleControl, {
                [styles.expanded]: option.isExpanded,
              })}
              id={option.id}
              onClick={toggleAccordion}
            >
              <UnboundedFont className={styles.accordionTitle} itemProp="name">
                {option.title}
              </UnboundedFont>
              <ButtonExpand
                type={option.isExpanded ? ButtonExpandType.CLOSE : ButtonExpandType.BLACK}
                className={styles.icon}
              />
            </div>
            <div
              className={styles.accordionContent}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className={styles.p} itemProp="text">
                <HTMLString html={option.description} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
